import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['lineItems', 'lineItem', 'lineItemTemplate', 'nullState', 'variantSelectLink']
  static values = { variantPricesLabel: String, productPricesLabel: String }

  selectedIdParam = 'selected_ids[]'
  urlParams = null

  variantSelectLinkTargetConnected() {
    this.url = new URL(this.variantSelectLinkTarget.href)
    this.urlParams = new URLSearchParams(this.url.search)
  }

  handleSelected(event) {
    const {
      detail: { variants },
    } = event

    // remove any items that were unchecked
    this.lineItemTargets.forEach((item) => {
      if (variants.find((variant) => variant.id === item.id)) return

      item.remove()
    })

    // add only new items
    variants.forEach((variant, i) => {
      if (this.lineItemTargets.find((lineItem) => lineItem.id === variant.id)) return

      this.createLineItem(variant, i)
    })

    // update link
    if (this.hasVariantSelectLinkTarget) {
      this.updateParams(variants)
    }
  }

  removeParam(id) {
    const ids = this.urlParams.getAll(this.selectedIdParam)
    const variantList = ids
      .filter((item) => item !== id)
      .map((item) => {
        return { id: item }
      })

    this.updateParams(variantList)
  }

  updateParams(variants) {
    this.urlParams.delete(this.selectedIdParam)

    variants.map((variant) => this.urlParams.append(this.selectedIdParam, variant.id))

    this.url.search = this.urlParams

    this.updateLink()
  }

  updateLink() {
    this.variantSelectLinkTarget.href = `${this.url.pathname}${this.url.search}`
  }

  createLineItem(data, index) {
    const { id, thumbnailUrl, thumbnailIsPlaceholder, name, sku, productName, productUrl, prices } = data

    // clone lineItem template
    let lineItem = document.createElement('div')
    lineItem.appendChild(this.lineItemTemplateTarget.content.cloneNode(true))
    let content = lineItem.innerHTML.replace(/NEW_RECORD/g, `${new Date().getTime() + index}`)
    lineItem.innerHTML = content

    // update cloned template using data from selected product
    lineItem.querySelector('[data-variant-id-field]').value = id
    lineItem.querySelector('[data-controller*="line-item"]').id = id
    lineItem
      .querySelector('[data-action*="line-item#remove"]')
      .setAttribute('data-orders--line-item-variant-id-param', id)
    if (thumbnailIsPlaceholder) {
      lineItem.querySelector('[data-variant-thumbnail]').remove()
    } else {
      lineItem.querySelector('[data-variant-thumbnail]').src = thumbnailUrl
      lineItem.querySelector('[data-variant-thumbnail-placeholder]').remove()
    }
    let link = lineItem.querySelector('[data-variant-link]')
    link.textContent = name
    link.href = productUrl
    if (sku) {
      lineItem.querySelector('[data-variant-sku]').textContent = `SKU: ${sku}`
    }

    if (this.hasNullStateTarget) {
      this.nullStateTarget.remove()
    }

    // set the data-value attribute on the super select so that it will use
    // price data to create options dynamically
    let priceSelect = lineItem.querySelector('[data-variant-price-select]')
    priceSelect.id = `variant_${id}_${new Date().getTime()}_prices_select`

    // Group prices based on the productPrice attribute
    let groupedPrices = {}
    prices.forEach((price) => {
      if (!groupedPrices[price.productPrice]) {
        groupedPrices[price.productPrice] = []
      }
      groupedPrices[price.productPrice].push(price)
    })

    Object.keys(groupedPrices).forEach((productPrice, index) => {
      let optgroup = document.createElement('optgroup')
      // Need to set this twice, once for the native select and once for the custom TomSelect renderer
      if (productPrice === 'true') {
        optgroup.label = this.productPricesLabelValue
        optgroup.setAttribute('label', this.productPricesLabelValue)
      } else {
        optgroup.label = this.variantPricesLabelValue
        optgroup.setAttribute('label', this.variantPricesLabelValue)
      }
      groupedPrices[productPrice].forEach((price) => {
        let option = new Option(price.systemPrice, price.id)
        if (price.visible && index === 0) {
          option.selected = true
        }
        // Setting these here so TomSelect can pick up the values for its renter template
        option.setAttribute('data-price', price.systemPrice)
        option.setAttribute('data-name', price.name)
        option.setAttribute('data-visible', price.visible)
        optgroup.appendChild(option)
      })
      priceSelect.appendChild(optgroup)
    })

    // append the lineItem to the form
    this.lineItemsTarget.append(lineItem)
  }
}
