import ApplicationController from '../application_controller'
import camelcaseKeys from 'camelcase-keys'

export default class extends ApplicationController {
  static targets = ['checkbox', 'parentCheckbox', 'selectedCount']
  static values = {
    selectedOptions: Array,
  }

  initialize() {
    this.selectedVariants = new Set(this.selectedOptionsValue)
    this.checkboxTargets.map((checkbox) => this.verifyChecked(checkbox))
  }

  connect() {
    this.updateSelected()
  }

  select() {
    if (event.target.checked) {
      this.selectedVariants = new Set([...this.selectedVariants, this.buildVariantData(event.target)])
    } else {
      this.selectedVariants = new Set(
        [...this.selectedVariants].filter((v) => v.id !== this.buildVariantData(event.target).id)
      )
    }

    this.updateSelected()
  }

  buildVariantData(variant) {
    return {
      id: variant.dataset.id,
      thumbnailUrl: variant.dataset.thumbnailUrl,
      thumbnailIsPlaceholder: variant.dataset.thumbnailIsPlaceholder == 'true',
      name: variant.dataset.name,
      sku: variant.dataset.sku,
      productName: variant.dataset.productName,
      productId: variant.dataset.productId,
      productUrl: variant.dataset.productUrl,
      prices: camelcaseKeys(JSON.parse(variant.dataset.prices), { deep: true }),
    }
  }

  /*
    emits an event about the selected variants:
    type: "products--variant-select:selected"
    detail: {
      variants: [
        {
          id: 123,
          thumbnailUrl: "path/to/thumbnail/image",
          name: "variant name",
          sku: "123456",
          productName: "Product name",
          productUrl: "url/to/product",
          prices: [{id, name, displayAmount}]
        }
      ]
    }
  */
  emitSelected() {
    const variants = this.getVariants()
    this.dispatch('selected', { detail: { variants } })
  }

  emitCancelled() {
    const variants = this.getVariants()
    this.dispatch('canceled', { detail: { variants } })
  }

  getVariants() {
    return this.selectedOptionsValue.map((variant) => {
      return variant
    })
  }

  updateSelected() {
    this.selectedOptionsValue = Array.from(this.selectedVariants)
    this.selectedCountTarget.innerHTML = `${this.selectedOptionsValue.length}`
  }

  get checked() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked)
  }

  parentCheckboxTargetConnected(parentCheckbox) {
    this.verifyChecked(parentCheckbox)
    const checkboxes = this.checkboxTargets.filter((checkbox) => checkbox.dataset.productName === parentCheckbox.name)
    parentCheckbox.indeterminate = this.checked.length > 0 && this.checked.length < checkboxes.length
    parentCheckbox.checked = this.checked.length === checkboxes.length
  }

  checkboxTargetConnected(checkbox) {
    this.verifyChecked(checkbox)
  }

  verifyChecked(checkbox) {
    checkbox.checked = [...this.selectedVariants].some((variant) => variant.id === checkbox.dataset.id)
  }
}
