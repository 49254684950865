import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['textField']

  insertValue(event) {
    let mergeTag = event.target.getAttribute('data-merge-tag')
    if (mergeTag == null) {
      let parentElement = event.target.parentElement
      while (parentElement != null) {
        mergeTag = parentElement.getAttribute('data-merge-tag')
        if (mergeTag != null) {
          event.target.setAttribute('data-merge-tag', mergeTag)
          break
        }
        parentElement = parentElement.parentElement
      }
    }

    const textField = this.textFieldTarget

    textField.value += mergeTag
    textField.setSelectionRange(textField.value.length, textField.value.length)
    textField.focus()
  }
}
