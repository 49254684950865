import ApplicationController from './application_controller'

export default class extends ApplicationController {
  shareOnFacebook(event) {
    const url = encodeURIComponent(event.params.url)
    const quote = encodeURIComponent(event.params.title)
    let hashtag = event.params.hashtags ? event.params.hashtags.split(',')[0].trim() : ''
    hashtag = hashtag ? encodeURIComponent(`#${hashtag}`) : ''

    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&hashtag=${hashtag}&quote=${quote}`
    this.openShareWindow(shareUrl)
  }

  shareOnTwitter(event) {
    const url = encodeURIComponent(event.params.url)
    const text = encodeURIComponent(event.params.title)
    const hashtags = event.params.hashtags ? encodeURIComponent(event.params.hashtags.split(',').join(',')) : ''

    const shareUrl = `https://twitter.com/intent/tweet/?text=${text}&url=${url}&hashtags=${hashtags}`
    this.openShareWindow(shareUrl)
  }

  shareOnLinkedIn(event) {
    const url = encodeURIComponent(event.params.url)

    const shareUrl = `https://www.linkedin.com/shareArticle?url=${url}`
    this.openShareWindow(shareUrl)
  }

  shareByEmail(event) {
    const url = encodeURIComponent(event.params.url)
    const subject = encodeURIComponent(event.params.subject || event.params.title)
    const body = encodeURIComponent(`${event.params.title} ${url}`)

    const shareUrl = `mailto:?subject=${subject}&body=${body}`
    window.location.href = shareUrl
  }

  shareBySms(event) {
    const body = `${event.params.title} ${event.params.url}`
    window.location.href = `sms:?&body=${body}`
  }

  openShareWindow(url) {
    window.open(
      url,
      '_blank',
      'height=450, width=550, top=' +
        (window.innerHeight / 2 - 275) +
        ', left=' +
        (window.innerWidth / 2 - 225) +
        ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0'
    )
  }
}
