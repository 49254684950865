/**
 * Use this controller to auto-execute a click() on an element (or elements) when it connects
 */
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['element']

  connect() {
    this.elementTargets.forEach((el) => el.click())
  }
}
