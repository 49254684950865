import Chart from 'stimulus-chartjs'

export default class extends Chart {
  connect() {
    super.connect()
    this.chart
    this.options
    this.defaultOptions
    this.applyDashedLineToLastSegment()
    this.chart.resize()
  }

  redraw(event) {
    const index = event.currentTarget.dataset.index
    this.chart.setDatasetVisibility(index, !this.chart.isDatasetVisible(index))
    this.chart.update()
  }

  applyDashedLineToLastSegment() {
    if (this.chart.data.datasets.length > 0) {
      this.chart.data.datasets.forEach((dataset) => {
        if (dataset.data.length >= 2) {
          dataset.segment = {
            borderWidth: (ctx) => (ctx.p1DataIndex === dataset.data.length - 1 ? 2 : 3),
            borderDash: (ctx) => (ctx.p1DataIndex === dataset.data.length - 1 ? [5, 5] : []),
          }
        }
      })

      this.chart.update()
    }
  }
}
