import { camelize } from './camelize.js'

// helper to allow shown/hidden classes to be defined at an element level
export function toggleToggleableTarget(target, value, dataKeyHiddenClass, dataKeyShownClass) {
  let classesToRemove, classesToAdd

  if (value === true) {
    classesToRemove = target.dataset[camelize(dataKeyHiddenClass)] || ''
    classesToAdd = target.dataset[camelize(dataKeyShownClass)] || ''
  } else {
    classesToRemove = target.dataset[camelize(dataKeyShownClass)] || ''
    classesToAdd = target.dataset[camelize(dataKeyHiddenClass)] || ''
  }

  if (classesToRemove !== '') {
    target.classList.remove(...classesToRemove.split(' '))
  }
  if (classesToAdd !== '') {
    target.classList.add(...classesToAdd.split(' '))
  }
}
