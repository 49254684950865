import ApplicationController from './application_controller'
import { useDispatch } from 'stimulus-use'

export default class extends ApplicationController {
  static values = {
    breakpoints: Array,
  }

  connect() {
    useDispatch(this) // eslint-disable-line react-hooks/rules-of-hooks
    this.layoutChanged = this.layoutChanged.bind(this)

    this.registerMediaQueries()
    this.addListenersToMediaQueries()
  }

  layoutChanged(mediaQuery) {
    this.dispatch('changed', { mediaQuery }) // prepends controller name e.g. page-layout:changed
  }

  disconnect() {
    this.removeListenersFromMediaQueries()
  }

  registerMediaQueries() {
    this.mediaQueries = {}

    this.breakpointsValue.forEach((breakpoint) => {
      const mediaQuery = `(min-width: ${breakpoint}px)`
      this.mediaQueries[mediaQuery] = window.matchMedia(mediaQuery)
    })
  }

  addListenersToMediaQueries() {
    for (const mediaQuery in this.mediaQueries) {
      this.mediaQueries[mediaQuery].addListener(this.layoutChanged)
    }
  }

  removeListenersFromMediaQueries() {
    for (const mediaQuery in this.mediaQueries) {
      this.mediaQueries[mediaQuery].removeListener(this.layoutChanged)
    }
  }
}
