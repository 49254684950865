import ApplicationController from './application_controller'

/**
 * Mai (Marketing AI) sometimes needs to be re-initialized when a new form is loaded
 * within a dialog/drawer.
 *
 * Usage:
 *  <%= form_with model: @model, data: { controller: 'mai' } do |form| %>
 */

export default class extends ApplicationController {
  connect() {
    window.initMai()
  }
}
