import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['tabs', 'mobileTabs']

  connect() {
    window.addEventListener('step-changed', this.setTab.bind(this))
  }

  setTab(event) {
    let tab = this.getTabBySelection(event.detail)
    this.tabsTarget.show(tab)
    this.mobileTabsTarget.show(tab)
  }

  getTabBySelection(step) {
    switch (step) {
      case 0:
        return 'type'
      case 1:
        return 'color'
      case 2:
        return 'image'
      case 3:
        return 'copy'
      case 4:
        return 'generate'
      default:
        return null
    }
  }
}
