import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    attachments: { type: Boolean, default: true },
    headings: { type: Boolean, default: true },
  }

  connect() {
    if (!this.attachmentsValue) this.disableAttachments()
    if (!this.headingsValue) this.disableHeadings()
  }

  // disable the trix editor's attachment(s) feature
  // see: https://github.com/basecamp/trix#storing-attached-files
  disableAttachments() {
    this.toolbarFileToolsElement?.remove()
    this.element.addEventListener('trix-file-accept', (event) => event.preventDefault(), true)
    this.element.addEventListener('trix-attachment-add', function (event) {
      if (!event.attachment.file) {
        event.attachment.remove()
      }
    })
  }

  // disable the trix editor's heading(s) feature
  disableHeadings() {
    // this.element.editor.deactivateAttribute("heading1")
    this.heading1Element?.remove()
  }

  // returns the trix editor attached to the trix-editor element
  get editor() {
    return this.element.editor
  }

  // returns the trix toolbar element
  get toolbarElement() {
    let sibling
    while ((sibling = this.element.previousElementSibling)) if (sibling.tagName.match(/trix-toolbar/i)) return sibling
    return null
  }

  // returns the toolbar file-tools button group element
  get toolbarFileToolsElement() {
    return this.toolbarElement?.querySelector('[data-trix-button-group=file-tools]')
  }

  // returns the toolbar heading1 button element
  get heading1Element() {
    return this.toolbarElement?.querySelector('[data-trix-attribute=heading1]')
  }
}
