import { RevealController } from 'stimulus-reveal'
import { toggleToggleableTarget } from '../utils/toggle_toggleable_target.js'
import { camelize } from '../utils/camelize.js'

export default class extends RevealController {
  static targets = ['toggleable', 'revealable', 'tab', 'tabSubmenu', 'submenuToggleable']
  static values = {
    open: Boolean, // same as RevealController
    targetSelector: String, // used by RevealController for our revealable targets
    activeTab: String,
    defaultTab: String,
    showSubmenu: Boolean,
    minWidthForClosed: Number,
    futurized: Boolean,
  }

  connect() {
    super.connect()
    this.layoutChanged()
    this.initialized = false
  }

  initializeMenu() {
    // We only need this extra logic to setup the menu when it is loaded with futurism.
    if (!this.futurizedValue) {
      return
    }

    document.addEventListener('futurism:appeared', (e) => {
      if (this.initialized) {
        return
      }

      // Ensure we are responding to the right futurism:appeared event.
      if (!document.getElementById('sidenav-mobile')) {
        return
      }

      if (this.defaultTabValue === 'dashboard') {
        this.hideSubmenu()
      } else {
        this.activeTabValue = this.defaultTabValue
        this.showSubmenuValue = true
      }
      this.updateTabTargets()
      this.updateSubmenuToggleableTargets()
      this.initialized = true
    })
  }

  show() {
    if (this.hasRevealableTarget) {
      super.open()
    } else {
      this.openValue = true
    }
  }

  hide() {
    if (this.hasRevealableTarget) {
      super.close()
    } else {
      this.openValue = false
    }
  }

  toggle() {
    if (this.hasRevealableTarget) {
      super.toggle()
    } else {
      this.openValue = !this.openValue
    }

    // We want to run this only the first time we show the menu.
    if (this.openValue && !this.initialized) {
      this.initializeMenu()
    }
  }

  hideSubmenu() {
    this.showSubmenuValue = false
    this.activeTabValue = ''
  }

  openValueChanged() {
    // value managed by RevealController
    this.updateToggleableTargets()
    // RevealController takes care of revealable targets
    // But we add support for toggleable targets that don't necessarily transition, whose classes we update
    if (this.openValue === true) {
      this.dispatch('shown')
    } else {
      this.dispatch('hidden')
    }
  }

  dispatch(eventName) {
    this.element.dispatchEvent(new CustomEvent(`${this.identifier}:${eventName}`))
  }

  layoutChanged() {
    if (window.innerWidth >= this.minWidthForClosedValue) {
      this.hide()
      this.resetToDefaultTab()
    } // else don't mess with the open value
  }

  updateToggleableTargets() {
    this.toggleableTargets.forEach((target) => {
      toggleToggleableTarget(
        target,
        this.openValue,
        `${this.identifier}-hidden-class`,
        `${this.identifier}-shown-class`
      )
    })
  }

  updateActiveTab(event) {
    let button = event.target.closest('button')
    const tab = button?.dataset[camelize(`${this.identifier}-tab`)]

    if (tab) {
      this.activeTabValue = tab
      if (button?.dataset[camelize(`${this.identifier}-tab-has-submenu`)] === 'true') {
        this.showSubmenuValue = true
      }
    }
  }

  resetToDefaultTab() {
    this.activeTabValue = this.defaultTabValue
    this.showSubmenuValue = false
  }

  activeTabValueChanged() {
    this.updateTabTargets()
  }

  updateTabTargets() {
    this.tabTargets.forEach((tab) => {
      tab.classList.toggle(
        tab.dataset[camelize(`${this.identifier}-tab-active-class`)],
        tab.dataset[camelize(`${this.identifier}-tab`)] === this.activeTabValue
      )
    })

    this.tabSubmenuTargets.forEach((tabSubmenu) => {
      tabSubmenu.classList.toggle(
        tabSubmenu.dataset[camelize(`${this.identifier}-tab-submenu-hidden-class`)],
        tabSubmenu.dataset[camelize(`${this.identifier}-tab`)] !== this.activeTabValue
      )
    })
  }

  showSubmenuValueChanged() {
    this.updateSubmenuToggleableTargets()
  }

  updateSubmenuToggleableTargets() {
    this.submenuToggleableTargets.forEach((target) => {
      toggleToggleableTarget(
        target,
        this.showSubmenuValue,
        `${this.identifier}-submenu-hidden-class`,
        `${this.identifier}-submenu-shown-class`
      )
    })
  }
}
