import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['image', 'loadingIndicator']

  initialize() {
    this.controller = new AbortController()
    this.imageLoadedBound = this.imageLoaded.bind(this)
  }

  connect() {
    this.imageTarget.complete ? this.removeLoadingIndicator() : this.setupListener()
  }

  setupListener() {
    this.imageTarget.addEventListener('load', this.imageLoadedBound, { signal: this.controller.signal })
  }

  removeLoadingIndicator() {
    this.loadingIndicatorTarget?.remove()
  }

  destroyListener() {
    this.controller.abort()
  }

  imageLoaded() {
    this.destroyListener()
    this.removeLoadingIndicator()
  }

  disconnect() {
    this.destroyListener()
  }
}
