import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static outlets = ['orders--line-items']

  remove({ params: { variantId } }) {
    this.element.remove()

    if (!this.hasOrdersLineItemsOutlet) return

    this.ordersLineItemsOutlet.removeParam(variantId)
  }
}
