import ApplicationController from './application_controller'
const ACTION_CLICK = 'action_click'

export default class extends ApplicationController {
  static targets = ['reactEditorIframe', 'navPagesContainer', 'actionClick']

  static values = {
    actionClickProcessed: Boolean,
  }

  get actionClickParam() {
    if (this._actionClickParam) return this._actionClickParam

    const params = new URLSearchParams(window.location.search)
    this._actionClickParam = params.get(ACTION_CLICK) || ''

    return this._actionClickParam
  }

  initialize() {
    this.lastEvent = null
  }

  connect() {
    this._processActionClick()
    this._initializePageLoad()
    window.addEventListener('message', this._editorCallbackMessageHandler.bind(this))
  }

  disconnect() {
    window.removeEventListener('message', this._editorCallbackMessageHandler.bind(this))
  }

  actionClickTargetConnected() {
    this._processActionClick()
  }

  openStyleGuide(event) {
    if (typeof this.reactEditorIframeTarget.contentWindow.pageHasUnsavedChanges === 'undefined') {
      let originalTarget = event.currentTarget
      this.reactEditorIframeTarget.addEventListener('load', () => {
        originalTarget.click()
      })
      return
    }

    if (this.reactEditorIframeTarget.contentWindow.openStyleGuide) {
      this.reactEditorIframeTarget.contentWindow.openStyleGuide()
    }
  }

  clearMenuHighlight(className) {
    ;[...this.element.querySelectorAll('.nav-item')].forEach((navItem) => navItem.classList.remove(className))
  }

  highlightMenuItem(event) {
    let className = event.params.css
    const parentNavItem = event.target.closest('.nav-item')
    this.clearMenuHighlight(className)
    if (parentNavItem) {
      parentNavItem.classList.add(className)
    }
  }

  clearEditingClasses(event) {
    if (event.target.id == 'blog_edit_drawer' || event.target.closest('#blog_edit_drawer')) {
      this.clearMenuHighlight('editing')
    }
  }

  _editorCallbackMessageHandler(event) {
    const data = event.data
    if (!data) {
      return
    }
    const action = data.action

    if (action === 'switch-page') {
      if (!this.lastEvent) {
        const target = document.querySelector(".nav-item[data-resource-url='" + window.location.pathname + "']")
        this.lastEvent = {
          params: {},
          target: target,
        }
      }
      this._setToActiveIfNoParams(this.lastEvent)
      this._navigateToSelectedItem(this.lastEvent, this._getPageId(this.lastEvent), data.unsavedChanges)
    } else if (action == 'cancel-switch-page') {
      if (data.error) {
        window.alert(data.error)
      }
    }
  }

  _initializePageLoad() {
    const iframeElement = document.getElementById('react-editor-iframe')
    const data = {
      action: 'change-page',
      search: window.location.search,
      resourceUrl: window.location.pathname,
    }

    if (iframeElement.isLoaded) {
      iframeElement.contentWindow.postMessage(data, '*')
    } else {
      iframeElement.addEventListener('load', () => {
        iframeElement.contentWindow.postMessage(data, '*')
      })
    }
  }

  // public
  changePage(event) {
    const iframeElement = document.getElementById('react-editor-iframe')
    this.lastEvent = event
    const data = {
      action: 'change-page',
      search: window.location.search,
      resourceUrl: this._getResourceUrl(event),
    }
    iframeElement.contentWindow.postMessage(data, '*')
  }

  // private
  _navigateToSelectedItem(event, id, unsavedChanges) {
    const targetElEventname = 'confirm_' + id

    this.highlightMenuItem(event)
    this._updateUrlParams(id, event)

    if (unsavedChanges === false) {
      window.dispatchEvent(new CustomEvent('editor:change-page', { bubbles: true }))
    }

    // Below sends a customEvent unique to each nav_item. This way, we can conditionally allow navigation
    this.dispatch(targetElEventname, { detail: { event: event } })
  }

  _updateUrlParams(id, customEvent) {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.delete('left_side_panel')
    urlParams.delete('action_click')

    let path = this._getResourceUrl(customEvent)
    if (urlParams.toString()) {
      path = path + '?' + urlParams.toString()
    }

    const newurl = window.location.protocol + '//' + window.location.host + '/' + path
    window.history.replaceState({ path: newurl }, '', path)
  }

  _getResourceUrl(event) {
    const path = event.target.dataset?.resourceUrl || event.target.closest('a').dataset?.resourceUrl
    return path
  }

  _getPageId(event) {
    const path = event.target.dataset?.id || event.target.closest('a').dataset?.id
    return path
  }

  _setToActiveIfNoParams(event) {
    if (Object.keys(event.params).length === 0) {
      this.clearMenuHighlight('editing')
      event.params = { css: 'active' }
    }
  }

  _processActionClick() {
    if (this.actionClickProcessedValue || !this.actionClickParam) return

    const target = this._findActionClickTarget()

    if (!target) return

    target.click()

    this.actionClickProcessedValue = true
  }

  _findActionClickTarget() {
    let target = null

    target = this.actionClickTargets.find((t) => t.classList.contains(this.actionClickParam))

    if (!target) {
      const selector = this.actionClickParam.match(/^[.|#]/) ? this.actionClickParam : `.${this.actionClickParam}`
      target = this.element.querySelector(selector)
    }

    return target
  }
}
