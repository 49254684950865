import ApplicationController from '../application_controller'
import $ from 'jquery'
import 'select2/dist/css/select2.min.css'
import Cookies from 'js-cookie'

require('select2')()
require('select2/dist/js/i18n/en')
require('select2/dist/js/i18n/es')
require('select2/dist/js/i18n/pt')

export default class extends ApplicationController {
  static targets = ['select']
  static values = {
    acceptsNew: Boolean,
    allowClear: Boolean,
    menuAsCheckboxes: { type: Boolean, default: false },
    closeOnSelect: { type: Boolean, default: true },
    containerWidth: String,
    data: Array,
    displaySelectionSummary: { type: Boolean, default: false },
    enableSearch: Boolean,
    maximumInputLength: Number,
    maximumSelectionLength: Number,
    noResultsMessage: String,
    selectedMessageTemplate: String,
    placeholder: { type: String, default: '' },
    searchUrl: String,
    selected: Object,
    selectDescriptor: String,
    selectedMessageTemplateCountKey: { type: String, default: '{{count}}' },
    selectedMessageTemplateTotalKey: { type: String, default: '{{total}}' },
    selectedMessageTemplateDescriptorKey: { type: String, default: '{{descriptor}}' },
    createPrefix: { type: String, default: '' },
  }

  // select2 does not re-trigger native events.
  // These events are what will be retriggered by this controller.
  static jQueryEventsToReissue = [
    'change',
    'select2:closing',
    'select2:close',
    'select2:opening',
    'select2:open',
    'select2:selecting',
    'select2:select',
    'select2:unselecting',
    'select2:unselect',
    'select2:clearing',
    'select2:clear',
  ]

  connect() {
    this.dispatchNativeEventBoundFn = this.dispatchNativeEvent.bind(this)
    this.initPluginInstance()
    this.closeSelectDropdownAfterClearing()
  }

  disconnect() {
    this.teardownPluginInstance()
  }

  // TODO may want to re-evaluate
  // This is called when going from initial undefined value to a blank string
  // when the controller is connected
  searchUrlValueChanged(newValue, prevValue) {
    if (newValue === prevValue) return

    this.initPluginInstance()
  }

  cleanupBeforeInit() {
    $(this.element).find('.select2-container--default').remove()
  }

  initPluginInstance() {
    const locale = Cookies.get('locale') || 'en'
    let $select2
    let options = {
      dropdownParent: $(this.element),
      language: locale,
    }

    if (this.hasDataValue) {
      options.data = this.dataValue
    }

    if (!this.enableSearchValue) {
      options.minimumResultsForSearch = -1
    }

    if (this.maximumSelectionLengthValue > 0) {
      options.maximumSelectionLength = this.maximumSelectionLengthValue
    }

    if (this.maximumInputLengthValue > 0) {
      options.maximumInputLength = this.maximumInputLengthValue
    }

    if (this.noResultsMessageValue) {
      options.language = {
        noResults: () => this.noResultsMessageValue,
      }
    }

    options.allowClear = this.allowClearValue
    options.placeholder = this.placeholderValue
    options.tags = this.acceptsNewValue
    options.templateResult = this.formatState
    options.templateSelection = this.formatState
    options.tokenSeparators = [',']
    options.width = this.containerWidthValue || 'style'
    options.menuAsCheckboxes = this.menuAsCheckboxesValue
    options.closeOnSelect = this.closeOnSelectValue

    if (this.createPrefixValue.length > 0) {
      options.createTag = (params) => {
        // Check if the tag already exists
        var term = $.trim(params.term)
        if (term === '') {
          return null
        }

        // Append "Create:" to the newly created tag
        return {
          id: term,
          text: `${this.createPrefixValue}: ${term}`,
          newTag: true, // Add this property to indicate it's a new tag
        }
      }
      options.templateSelection = (selection) => {
        // Modify the appearance of the selected tag
        if (selection.id && selection.newTag) {
          selection.text = selection.text.replace(`${this.createPrefixValue}:`, '')
          return selection.text
        }
        return selection.text
      }
    }

    if (this.searchUrlValue) {
      options.ajax = {
        url: this.searchUrlValue,
        dataType: 'json',
        // We enable pagination by default here
        data: function (params) {
          var query = {
            search: params.term,
            page: params.page || 1,
          }
          return query
        },
        // Any additional params go here...
      }
    }

    if (this.displaySelectionSummaryValue == true) {
      options.allowClear = true
      options.templateSelection = (selected, total) => { // eslint-disable-line
        if (selected.length == 0) {
          return options.placeholder
        }

        return this.updateSelectionText(selected, options.maximumSelectionLength)
      }
    }

    this.cleanupBeforeInit() // in case improperly torn down
    this.pluginMainEl = this.selectTarget // required because this.selectTarget is unavailable on disconnect()

    // check if dropdown is in a sidepanel
    var $sidepanel = $(this.pluginMainEl).parents('#side_panel')
    if ($sidepanel.length > 0) {
      //scope to sidepanel if applicable
      options.dropdownParent = $('#side_panel')

      // Hacking this event to prevent clickOutside event from triggering in controllers/panel.js
      $(this.pluginMainEl).on('select2:unselect', function () {
        event.cancelBubble = true
        return
      })
    }

    if (this.displaySelectionSummaryValue == true) {
      $(this.pluginMainEl).select2MultiCheckboxes(options)
    } else {
      $select2 = $(this.pluginMainEl)?.select2(options)
      if (this.menuAsCheckboxesValue && $select2) {
        $select2.data('select2').$dropdown.addClass('select2--checkbox')
      }
    }

    this.initReissuePluginEventsAsNativeEvents()

    // https://select2.org/data-sources/ajax#default-pre-selected-values
    if (this.hasSelectedValue) {
      var newOption = new Option(this.selectedValue.text, this.selectedValue.id, true, true)
      $(this.pluginMainEl).append(newOption).trigger('change')
    }
  }

  closeSelectDropdownAfterClearing() {
    // prevent the select menu from opening after we close it via allow_clear option
    // https://github.com/select2/select2/issues/3320
    $('select').on('select2:clear', function () {
      $(this).on('select2:opening.cancelOpen', function (evt) {
        evt.preventDefault()

        $(this).off('select2:opening.cancelOpen')
      })
    })
  }

  updateSelectionText(selection, total) {
    let template = this.selectedMessageTemplateValue

    template = template.replace(this.selectedMessageTemplateCountKeyValue, selection.length)
    template = template.replace(this.selectedMessageTemplateTotalKeyValue, total)
    template = template.replace(this.selectedMessageTemplateDescriptorKeyValue, this.selectDescriptorValue)

    return template.trim()
  }

  teardownPluginInstance() {
    if (this.pluginMainEl === undefined) {
      return
    }

    // ensure there are no orphaned event handlers
    this.teardownPluginEventsAsNativeEvents()

    // revert to original markup, remove any event listeners
    $(this.pluginMainEl)?.select2('destroy')
  }

  // select2 does not re-trigger native events.
  // this tread details the issue: https://github.com/select2/select2/issues/1908
  // Direct link to the recommended fix: https://github.com/select2/select2/issues/1908#issuecomment-871095578
  // note one(...), not on(...), which triggers once and detaches. Otherwise you get stackoverflow as
  // this triggers more on(change) events.
  initReissuePluginEventsAsNativeEvents() {
    this.constructor.jQueryEventsToReissue.forEach((eventName) => {
      $(this.pluginMainEl).one(eventName, this.dispatchNativeEventBoundFn)
    })
  }

  teardownPluginEventsAsNativeEvents() {
    this.constructor.jQueryEventsToReissue.forEach((eventName) => {
      $(this.pluginMainEl).off(eventName)
    })
  }

  // BT deviation
  // we've opted to not prepend $ to reissued event names.
  dispatchNativeEvent(event) {
    this.pluginMainEl.dispatchEvent(
      new CustomEvent(event.type, {
        detail: { event: event },
        bubbles: true,
        cancelable: false,
      })
    )

    // rebind event that was fired.
    $(this.pluginMainEl).one(event.type, this.dispatchNativeEventBoundFn)
  }

  // https://stackoverflow.com/questions/29290389/select2-add-image-icon-to-option-dynamically
  formatState(opt) {
    var imageUrl = $(opt.element).attr('data-image')
    var imageStyle = $(opt.element).attr('data-image-style')
    if (imageStyle) {
      // use this
    } else {
      imageStyle = 'default'
    }
    var imageHtml = ''
    if (imageUrl) {
      imageHtml = '<img src="' + imageUrl + '" class="' + imageStyle + '" /> '
      return $(
        '<span class="flex items-center">' +
          imageHtml +
          '<span class="block truncate">' +
          sanitizeHTML(opt.text) +
          '</span>' +
          '</span>'
      )
    }
    var iconClass = $(opt.element).attr('data-icon-class')
    if (iconClass) {
      return $('<span>' + `<i class="${iconClass}"></i>` + sanitizeHTML(opt.text) + '</span>')
    }
    return $('<span>' + sanitizeHTML(opt.text) + '</span>')
  }
}

// https://portswigger.net/web-security/cross-site-scripting/preventing
function sanitizeHTML(str) {
  return str?.replace(/[^\w. ]/gi, function (c) {
    return '&#' + c.charCodeAt(0) + ';'
  })
}
