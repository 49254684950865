import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = ['list', 'nullState', 'variantListItem', 'template']
  static values = { variants: Array }

  connect() {
    this.existingVariantIds = Array.from(this.variantsValue)
    this.selectedProductIds = new Set(this.variantListItemTargets.map((item) => item.dataset.productId))
  }

  handleVariantSelection(event) {
    this.listTarget.classList.remove('hidden')

    event.detail.variants.forEach((variant) => {
      if (this.existingVariantIds.includes(variant.id)) {
        return
      }

      if (!this.selectedProductIds.has(variant.productId)) {
        this.selectedProductIds.add(variant.productId)
        this.addItemToList(variant)
      } else {
        this.updateExistingProductCount(variant)
      }

      this.updateExistingVariantIds(variant.id)
    })

    const selectedIds = event.detail.variants.map((variant) => variant.id)

    this.existingVariantIds.forEach((existingId) => {
      if (!selectedIds.includes(existingId)) {
        this.removeVariantFromProductsList(existingId)
      }
    })

    this.toggleNullState()
  }

  addItemToList(variant) {
    const template = this.templateTarget
    const listItem = document.importNode(template.content, true).querySelector('li')
    const currentIds = new Set(JSON.parse(listItem.dataset.variantIds))

    listItem.dataset.productId = variant.productId

    if (this.productWithoutVariants(variant) || !currentIds.has(variant.id)) {
      currentIds.add(variant.id)
    }

    listItem.dataset.variantIds = JSON.stringify(Array.from(currentIds))
    listItem.querySelector('[data-variant-name]').textContent = variant.productName
    listItem.querySelector('[data-variant-thumbnail]').src = variant.thumbnailUrl

    if (!this.productWithoutVariants(variant)) {
      this.updateVariantCount(listItem)
    }

    this.listTarget.appendChild(document.importNode(listItem, true))
    currentIds.clear()
  }

  updateExistingProductCount(variant) {
    const existingProduct = this.variantListItemTargets.find((item) => item.dataset.productId === variant.productId)
    const variantIds = JSON.parse(existingProduct?.dataset?.variantIds || 'null') || []

    if (this.productWithoutVariants(variant) || variantIds.includes(variant.id)) {
      return
    }

    variantIds.push(variant.id)
    existingProduct.dataset.variantIds = JSON.stringify(variantIds)
    this.updateVariantCount(existingProduct)
  }

  updateExistingVariantIds(id) {
    if (this.existingVariantIds.includes(id)) {
      return
    }

    this.existingVariantIds.push(id)
    this.variantsValue = this.existingVariantIds
  }

  productWithoutVariants(variant) {
    return variant.productName === variant.name
  }

  updateVariantCount(listItem) {
    const count = JSON.parse(listItem.dataset.variantIds).length
    const pluralized = count > 1 ? `${listItem.dataset.variantCopy}s` : listItem.dataset.variantCopy
    listItem.querySelector('[data-variant-count]').textContent = `${count} ${pluralized}`
  }

  handleClearVariants() {
    this.existingVariantIds.forEach((currentId) => {
      this.removeVariantFromProductsList(currentId)
    })

    this.variantListItemTargets.forEach((element) => {
      element.remove()
    })

    this.listTarget.classList.add('hidden')
    this.nullStateTarget.classList.remove('hidden')

    this.dispatch('variantsCleared', { detail: { variants: [] } })
  }

  removeVariantFromProductsList(id) {
    this.existingVariantIds.splice(this.existingVariantIds.indexOf(id), 1)

    this.variantListItemTargets.forEach((listItem) => {
      const variantIds = JSON.parse(listItem.dataset.variantIds)

      if (variantIds.includes(id)) {
        variantIds.splice(variantIds.indexOf(id), 1)
        listItem.dataset.variantIds = JSON.stringify(variantIds)
        this.selectedProductIds.delete(listItem.dataset.productId)

        if (variantIds.length === 0) {
          listItem.remove()
        } else {
          this.updateVariantCount(listItem)
        }
      }
    })

    this.dispatch('removeVariantFromProductsList', { detail: { id: id } })
  }

  toggleNullState() {
    if (this.variantListItemTargets.length === 0) {
      this.nullStateTarget.classList.remove('hidden')
    } else {
      this.nullStateTarget.classList.add('hidden')
    }
  }
}
