import ApplicationController from './application_controller'

export default class extends ApplicationController {
  attachFormData(event) {
    setTimeout(() => {
      this.element.dataset.form = this.formJSON
      this.emitFormUpdatedEvent()
    })
  }

  get form() {
    return this.element.closest('form')
  }

  get formData() {
    if (!this.form) return {}
    return Object.fromEntries(new FormData(this.form))
  }

  get formJSON() {
    return JSON.stringify(this.formData)
  }

  emitFormUpdatedEvent() {
    const event = new CustomEvent('formUpdated', { bubbles: true, cancelable: true })
    this.element.dispatchEvent(event)
  }
}
