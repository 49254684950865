import ApplicationController from '../application_controller'
import TomSelect from 'tom-select'
import { get } from '@rails/request.js'

const renderConfigs = {
  // Customize any of the HTML rendered by passing in a custom render object.
  // Must conform to the shape defined here:
  // https://github.com/orchidjs/tom-select/blob/master/doc_src/pages/docs/index.md#render-templates
  codes: {
    render: {
      option: function (data, escape) {
        return `
          <div class="py-2 px-3">
            <sl-tooltip class="sl-tooltip" content="${escape(data.code)}">
              <div class="truncate">${escape(data.text)}</div>
            </sl-tooltip>
          </div>
        `
      },
    },
  },
  contacts: {
    render: {
      option: function (data, escape) {
        return `
          <div class="py-2 px-3 flex items-center space-x-3">
            <div class="flex-shrink-0">
              <img class="h-6 w-6 rounded-full" src="${escape(data.avatar_url)}" alt="">
            </div>
            <div class="flex flex-col min-w-0">
              <div class="font-medium">
                ${escape(data.full_name)}
              </div>
              <div class="truncate text-xs font-light">
                ${escape(data.email_address)}
              </div>
            </div>
          </div>
        `
      },
      item: function (data, escape) {
        return `
          <div class="flex items-center space-x-2 rounded-lg min-w-0">
          ${data.avatar_url ? `<img class="h-4 w-4 rounded-full" src="${escape(data.avatar_url)}" alt="">` : ''}
            <span class="truncate text-sm">
              ${escape(data.full_name || data.email_address || data.text)}
            </span>
          </div>
        `
      },
    },
  },
  prices: {
    render: {
      option: function (data, escape) {
        return `
          <div class="py-2 px-3">
            <div class="flex items-center justify-between space-x-1">
              <span class="font-medium">${escape(data.price)}</span>
              <span class="inline-block shrink-0 h-2 w-2 rounded-full ${
                data.visible === 'true' ? 'bg-green-600' : 'bg-coolGray-400'
              }"></span>
            </div>
            ${data.name && `<div class="truncate text-xs">${escape(data.name)}</div>`}
          </div>
        `
      },
      item: function (data, escape) {
        return `
          <div class="pr-7">${escape(data.text)}</div>
        `
      },
      optgroup_header: function (data, escape) {
        return `
          <div class="flex items-center justify-between px-3 pt-2 pb-1 border-b border-coolGray-100">
            <div class="text-coolGray font-semibold text-xs">
              ${escape(data.label)}
            </div>
            <div class="text-coolGray font-semibold text-xs text-right">
              Visible
            </div>
           </div>
        `
      },
    },
  },
  pages: {
    render: {
      option: function (data, escape) {
        return `
          <div class="py-2 px-3 flex items-center space-x-3">
            <div class="flex flex-col min-w-0">
              <div class="font-medium">
                ${escape(data.name)}
              </div>
              <div class="truncate text-xs font-light">
                ${escape(data.path)}
              </div>
            </div>
          </div>
        `
      },
      item: function (data, escape) {
        return `
          <div class="flex items-center space-x-2 rounded-lg min-w-0">
            <span class="truncate text-sm">
              ${escape(data.name)}
            </span>
          </div>
        `
      },
    },
  },
  tags: {
    render: {
      option: function (data, escape) {
        const color = data.color || '#D7E0E5'
        return `
          <div>
            <div class="flex items-center space-x-2">
              <div class="h-3 w-3 rounded-full" style="background-color: ${color}"></div>
              <div class="truncate">${escape(data.text)}</div>
            </div>
          </div>
        `
      },
      item: function (data, escape) {
        const color = data.color || '#D7E0E5'
        return `
          <div>
            <div class="flex items-center space-x-2">
              <div class="h-3 w-3 rounded-full ring-1 ring-coolGray-200" style="background-color: ${color}"></div>
              <div class="truncate">${escape(data.text)}</div>
            </div>
          </div>
        `
      },
    },
  },
  themes: {
    render: {
      option: function (data, escape) {
        return `
          <div class="py-2 px-3">
            <div class="flex items-center justify-between">
              <div class="flex items-center space-x-3">
                ${data.imgSrc ? `<img src="${escape(data.imgSrc)}" class="w-8 h-6">` : ''}
                <div class="truncate">${escape(data.text)}</div>
              </div>
              <button
                type="button"
                data-controller="tooltip"
                data-tooltip-content-value="Preview Theme Pages"
                data-turbo-command="Themes::ViewThemePages"
                data-theme-id="${escape(data.themeId)}"
              >
                <i class="fa-solid fa-eye text-coolGray-400"></i>
              </button>
            </div
          </div>
        `
      },
      item: function (data, escape) {
        return `
          <div>
            <div class="flex items-center space-x-3">
              ${data.imgSrc ? `<img src="${escape(data.imgSrc)}" class="w-8 h-6">` : ''}
              <div class="truncate">${escape(data.text)}</div>
            </div>
          </div>
        `
      },
      dropdown: function () {
        return `
          <div>
            <div class="flex items-center py-2 px-3 border border-b border-coolGray-150">
             <span class="text-coolGray-900 mr-1">Don't see the one you want?</span>
             <button
              type="button"
              class="link"
              data-turbo-command="Marketplaces::Themes::OpenGallery"
              >
                Explore New Themes
              </button>
             </div>
          </div>
        `
      },
    },
  },
  mergeTags: {
    render: {
      option: function (data, escape) {
        return `
          <div class="py-2 px-3 flex items-center space-x-3">
            <div class="flex flex-col min-w-0">
              <div class="font-medium">
                ${escape(data.name)}
              </div>
              <div class="truncate text-xs font-light">
                ${escape(data.tag)}
              </div>
            </div>
          </div>
        `
      },
      item: function (data, escape) {
        return `
          <div class="flex items-center space-x-2 rounded-lg min-w-0">
            <span class="truncate text-sm">
              ${escape(data.name)}
            </span>
          </div>
        `
      },
    },
  },
}

const loadConfigs = {
  // uses `fetch` to access external urls
  external: function (baseUrl, queryParam, pathToData) {
    return {
      load: async function (query, callback) {
        var url = `${baseUrl}?${queryParam}=` + encodeURIComponent(query)
        fetch(url)
          .then((response) => response.json())
          .then((json) => {
            callback(getValueByKeys(json, pathToData))
          })
          .catch(() => {
            callback()
          })
      },
    }
  },
  // uses `@rails/requestjs` to access internal application
  internal: function (baseUrl, queryParam, pathToData) {
    return {
      load: async function (query, callback) {
        const url = `${baseUrl}?${queryParam}=` + encodeURIComponent(query)
        const response = await get(url, { responseKind: 'json' })

        if (response.ok) {
          const data = await response.json
          callback(getValueByKeys(data, pathToData))
        } else {
          callback()
        }
      },
    }
  },
}

const getValueByKeys = (data, keys) => {
  let result = data
  for (let key of keys) {
    if (result[key] === undefined) {
      return undefined
    }
    result = result[key]
  }
  return result
}

export default class extends ApplicationController {
  static targets = ['select']

  static values = {
    checkboxOptions: { type: Boolean, default: false },
    loadConfigName: { type: String, default: 'external' },
    multiple: { type: Boolean, default: false },
    queryParam: { type: String, default: 'q' },
    remoteUrl: { type: String, default: '' },
    remoteDataPath: { type: Array, default: ['items'] },
    renderConfigName: { type: String, default: '' },
    settings: { type: Object, default: {} },
    focusOnLoad: { type: Boolean, default: false },
  }

  connect() {
    const settings = this.initOptions()

    this.tomSelect = new TomSelect(this.selectTarget, settings)
    if (this.focusOnLoadValue) {
      this.focus()
    }
  }

  initOptions() {
    let defaultOptions = {
      plugins: {},
      create: true,
      copyClassesToDropdown: false,
      render: {},
      hidePlaceholder: true,
      maxOptions: null,
    }

    if (this.multipleValue) {
      defaultOptions.plugins['remove_button'] = {}
      defaultOptions.plugins['clear_button'] = {
        html: (data) => {
          return `
            <div
              class="${data.className}"
              title="${data.title}"
              data-controller="tooltip"
              data-tooltip-content-value="${data.title}"
            >
              <i class="fa fa-circle-x"></i>
            </div>
          `
        },
      }
    }

    if (this.checkboxOptionsValue) {
      defaultOptions.plugins['checkbox_options'] = {}
    }

    // don't allow deleting the currently selected option
    // if in single select mode. Use include_blank + allow_empty_option instead.
    if (!this.multipleValue) {
      defaultOptions['onDelete'] = function () {
        return false
      }
    }

    let combinedOptions = {
      ...defaultOptions,
      ...this.settingsValue,
      ...(this.hasRenderConfigNameValue && renderConfigs[this.renderConfigNameValue]),
      ...(this.hasLoadConfigNameValue &&
        loadConfigs[this.loadConfigNameValue](this.remoteUrlValue, this.queryParamValue, this.remoteDataPathValue)),
    }

    combinedOptions.render.loading = function () {
      return `
        <div class="flex items-center justify-center p-3">
          <sl-spinner class="sl-spinner sl-spinner--medium"></sl-spinner>
        </div>
      `
    }

    return combinedOptions
  }

  // Tom Select API Actions
  // Add more as needed: https://tom-select.js.org/docs/api/
  open() {
    this.tomSelect.open()
  }

  close() {
    this.tomSelect.close()
  }

  focus() {
    this.tomSelect.focus()
  }
}
